import { useNuxtApp } from '#imports';
import type { FetchError } from 'ofetch';
import type { Ref } from 'vue';
import type { GetCountriesApp } from '~/helpers/parsers/CountriesDTO';

type CountryStoreType = {
  countryList: Ref<GetCountriesApp[] | null>;
  setCountries: (country: GetCountriesApp[]) => void;
  getCountries: () => Promise<GetCountriesApp[] | null>;
}
export const countriesStore = defineStore('countries-store', (): CountryStoreType => {
  const { $api } = useNuxtApp();
  const countryList = ref<GetCountriesApp[] | null>(null);

  function setCountries (country: GetCountriesApp[]) {
    countryList.value = country;
  }

  async function getCountries (): Promise<GetCountriesApp[] | null> {
    const res = await $api('/api-front/get-countries', {
      baseURL: '/'
    }).catch((err: FetchError) => {
      console.log('error during getting countries:', err.data);
      return null;
    });

    if (res && res.length) {
      setCountries(res);

      return res;
    }

    return null;
  }

  return {
    countryList,
    setCountries,
    getCountries
  };
});
